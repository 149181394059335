<template>
<div>
    <v-dialog v-model="$store.state.dialog" width="1800">
        <v-card flat class="card">
            <v-toolbar outlined flat>
                <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                <v-spacer />
                <v-btn @click="close()" icon>
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-form @submit.prevent="submit()" ref="form" v-model="valid">
                <!-- <pre dir="ltr">
                {{ item }}
                </pre> -->
                <v-container fluid>
                    <v-card outlined>
                        <v-container fluid>

                            <v-alert color="primary" text>
                                <h3>بيانات التاجر</h3>
                            </v-alert>
                            <v-row>
                                <aAutoComplete v-model="item.merchantId" :loading="$global.state.loading" :fieldTitle="true" :items="merchants" item-text="fullName" item-value="id" title="التاجر" :cols="12" :md="3" :required="true" />
                                <aAutoComplete @input="getReceivingAreas()" :loading="$global.state.loading" :fieldTitle="true" v-model="item.receivingGovernorateId" :items="$global.state.governorates" item-text="name" item-value="id" title="محافظة الاستلام" :cols="12" :md="3" :required="true" />
                                <aAutoComplete v-model="item.receivingAreaId" :loading="$global.state.loading" :fieldTitle="true" :items="receiving_areas" item-text="name" item-value="id" title="منطقة الاستلام" :cols="12" :md="3" :required="false" />
                            </v-row>

                            <br />
                            <v-alert color="primary" text>
                                <h3>بيانات الزبون</h3>
                            </v-alert>
                            <v-row>
                                <aTextField v-model="item.customerName" title="اسم الزبون الثلاثي" :cols="12" :md="3" :required="true" :fieldTitle="true" />
                                <aPhoneNumber v-model="item.customerPhoneNumber" title="رقم هاتف 1" :cols="12" :md="3" :required="true" :fieldTitle="true" />
                                <aPhoneNumber v-model="item.customerPhoneNumber2" title="رقم هاتف 2" :cols="12" :md="3" :required="false" :fieldTitle="true" />
                                <aAutoComplete @input="getDeliveryAreas()" :loading="$global.state.loading" :fieldTitle="true" v-model="item.deliveryGovernorateId" :items="$global.state.governorates" item-text="name" item-value="id" title="محافظة التوصيل" :cols="12" :md="3" :required="true" />
                                <aAutoComplete v-model="item.deliveryAreaId" :loading="$global.state.loading" :fieldTitle="true" :items="dlivery_areas" item-text="name" item-value="id" title="منطقة التوصيل" :cols="12" :md="3" :required="false" />

                            </v-row>

                            <br />
                            <v-alert color="primary" text>
                                <h3>بيانات الشحنة</h3>
                            </v-alert>
                            <v-row>
                                <aTextField v-model="item.no" title="رقم الشحنة" :cols="12" :md="3" :required="true" :fieldTitle="true" />
                                <aTextField v-model="item.packageContent" title="المحتويات" :cols="12" :md="3" :fieldTitle="true" />
                                <aTextField v-model="item.packageItemsCount" title="الكمية" :cols="12" :md="3" :fieldTitle="true" />
                                <aTextField v-model="item.deliveryAddress" title="عنوان التوصيل" :cols="12" :md="3" :fieldTitle="true" />
                                <aMoneyField v-model="item.packageValue" title="قيمة الشحنة" :cols="12" :md="3" :required="true" suffix="د.ع" :fieldTitle="true" />
                                <aMoneyField :key="item.deliveryCost" v-model="item.deliveryCost" title="كلفة التوصيل" :cols="12" :md="3" :required="true" suffix="د.ع" :fieldTitle="true" />
                                <aMoneyField v-model="item.extraSize" title="حجم إضافي" :cols="12" :md="3" :fieldTitle="true" />
                                <aMoneyField v-model="item.sizeCost" title="تكلفة الحجم" :cols="12" :md="3" suffix="د.ع" :fieldTitle="true" />
                                <aMoneyField :key="item.packageCost" v-model="item.packageCost" title="تكلفة الشحنة" :cols="12" :md="3" :required="true" suffix="د.ع" :disabled="true" :fieldTitle="true" />
                                <aAutoComplete v-if="$store.state.user.userType.id == 1" v-model="item.warehouseId" :loading="$global.state.loading" :fieldTitle="true" :items="warehouses" item-text="name" item-value="id" title="المخزن" :cols="12" :md="3" :required="false" />

                            </v-row>

                        </v-container>
                    </v-card>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-btn type="submit" :disabled="!valid" :loading="$global.state.loading" x-large color="primary" class="btn">حفظ</v-btn>
                            <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined>إلغاء</v-btn>
                        </v-row>
                    </v-col>
                </v-container>
            </v-form>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {

    data() {
        return {
            item: {},
            valid: false,
            loading: false,
            receiving_areas: [],
            dlivery_areas: [],
            merchants: [],
            delegaters: [],
            warehouses: [],
        }
    },

    mounted() {

        this.$eventBus.$on('fill-fields', () => {
            this.item = this.$store.state.itemDetails
        })

        this.$eventBus.$on('fetch-data', () => {
            this.reset()
            this.getMerchants()
            this.getDeliveryAreas()
            this.getReceivingAreas()
            this.getWarehouses()
            // this.getDelegaters()
        })

    },
    computed: {
        calculatedPackageCost() {
            const packageValue = parseFloat(this.item.packageValue) || 0;
            const deliveryCost = parseFloat(this.item.deliveryCost) || 0;
            const sizeCost = parseFloat(this.item.sizeCost) || 0;

            const calculatedCost = (packageValue + deliveryCost + sizeCost).toFixed(2);
            console.log('Calculated Package Cost:', calculatedCost);

            return calculatedCost;
        }
    },

    watch: {
        item: {
            deep: true,
            handler() {
                // Update form validity whenever a field changes
                this.checkFormValidity();
            },
        },
        calculatedPackageCost: {
            immediate: true,
            handler(newValue) {
                console.log('Watch triggered with new value:', newValue);
                this.item.packageCost = newValue;
            }
        }
    },

    methods: {

        checkFormValidity() {
            // List of required fields
            const requiredFields = [
                'no',
                'merchantId',
                'customerName',
                'customerPhoneNumber',
                'receivingGovernorateId',
                'deliveryGovernorateId',
                'packageValue',
                'packageCost',
                'deliveryCost',
            ];

            // Check if all required fields are filled
            this.valid = requiredFields.every(field => !!this.item[field]);
        },

        getDeliveryCost() {
            if (this.item.deliveryGovernorateId == 1) {
                this.item.deliveryCost = 3000;
            } else {
                this.item.deliveryCost = 5000;
            }
            const packageValue = parseFloat(this.item.packageValue) || 0;
            const deliveryCost = parseFloat(this.item.deliveryCost) || 0;
            const sizeCost = parseFloat(this.item.sizeCost) || 0;

            const calculatedCost = (packageValue + deliveryCost + sizeCost).toFixed(2);
            this.item.packageCost = calculatedCost
        },

        reset() {
            this.item = {
                "no": "",
                "merchantId": "",
                "customerName": "",
                "customerPhoneNumber": "",
                "customerPhoneNumber2": "",
                "receivingGovernorateId": 1,
                "receivingAreaId": "",
                "deliveryGovernorateId": 1,
                "deliveryAreaId": "",
                "deliveryAddress": "",
                "packageContent": "",
                "packageItemsCount": "",
                "packageValue": "",
                "packageCost": "",
                "deliveryCost": "",
                "extraSize": "",
                "sizeCost": "",
                "delegaterId": "",
                "warehouseId": this.$store.state.user.warehouse != null ? this.$store.state.user.warehouse.id : '',
                "statusId": 1,
            }
        },

        submit() {
            if (this.valid) {
                if (this.$store.state.itemDetails.dialogType == 'add') {
                    this.addItem()
                } else {
                    this.editItem()
                }
            }else{
                this.$service.faild('يجب ملئ جميع الحقول المطلوبة!')
            }
        },

        async getMerchants() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`User?PageIndex=1&PageSize=100&UserTypeId=${this.$global.state.roleType.merchant}`)
                this.merchants = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        async getWarehouses() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Warehouse?PageIndex=1&PageSize=100`)
                this.warehouses = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        // async getDelegater() {
        //     this.$global.state.loading = true
        //     try {
        //         const res = await this.$http.get(`User?PageIndex=1&PageSize=100&UserTypeId=${this.$global.state.roleType.delivery}`)
        //         this.delegaters = res.data.result
        //     } catch (err) {
        //         console.log(err)
        //         this.$service.faild(err.response.data.message)
        //     } finally {
        //         this.$global.state.loading = false
        //     }
        // },

        async getReceivingAreas() {
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Area?PageIndex=1&PageSize=10000&GovernorateId=${this.item.receivingGovernorateId}`)
                this.receiving_areas = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        async getDeliveryAreas() {
            this.getDeliveryCost()
            this.$global.state.loading = true
            try {
                const res = await this.$http.get(`Area?PageIndex=1&PageSize=10000&GovernorateId=${this.item.deliveryGovernorateId}`)
                this.dlivery_areas = res.data.result
            } catch (err) {
                console.log(err)
                this.$service.faild(err.response.data.message)
            } finally {
                this.$global.state.loading = false
            }
        },

        addItem() {
            this.$global.state.loading = true
            this.item.statusId = 1;
            this.$http.post(`Order`, this.item).then(res => {
                this.$service.success('تم الإضافة بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
                this.close()
            }).catch(err => {
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.ify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        editItem() {
            this.$global.state.loading = true
            this.$http.put(`Order/${this.item.id}`, this.item).then(res => {
                this.close()
                this.$service.success('تم التعديل بنجاح')
                this.$eventBus.$emit(`${this.$route.path}`)
            }).catch(err => {
                // this.$service.faild(err.response.data.message)
                this.$service.faild('حدث خطأ')
                this.$service.faild(err.data.message)
                this.$service.faild(JSON.ify(err.data.result))
            }).finally(() => this.$global.state.loading = false)
        },

        close() {
            this.$store.commit('setDialog')
            this.reset()
            this.resetValidation()
            this.$store.commit('setItemDetails', {})
        },

        resetValidation() {
            this.$refs.form.resetValidation()
        },

    },
}
</script>
